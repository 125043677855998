//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Radar from '~/node_modules/radar-sdk-js'
import '~/node_modules/radar-sdk-js/dist/radar.css'
import maplibregl from 'maplibre-gl'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            map: false,
            activeLocation: null
        }
    },
    props: {
        marker: {
            default: () => {
                return {
                    src: 'https://a.storyblok.com/f/177063/48x65/e69179b243/23_tfk_locationtag.png'
                    // width and height are automatically added based on src dimensions
                }
            }
        },
        popups: {
            default: () => {
                return {
                    show: true,
                    offset: 'default' // to override use a number
                }
            }
        },
        location: { // single location
            default: () => {
                return false
            }
        }
    },
    async mounted() {
        // get pin dimensions 
        const marker = await new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img)
            img.onerror = () => reject()
            img.src = this.marker.src
        })
        this.marker.width = marker.width + 'px'
        this.marker.height = marker.height + 'px'
        // set offset
        if (this.popups.offset == 'default') {
            this.popups.offset = marker.height - 20
        }

        this.initialize()
        if (this.location) {
            this.addMarker()
        } else {
            this.addMarkers()
        }

        this.$nextTick(() => {
            document.addEventListener('click', function (e) {
                if (e.target.hasAttribute('data-order')) {
                    this.startOrder(this.activeLocation)
                }
            }.bind(this))
        })
    },
    computed: {
        ...mapState(['locationList', 'allLocations']),
        locations() {
            if (this.locationList && this.locationList.length) {
                return this.locationList
            } else {
                return this.allLocations
            }
        },
        popup() {
            if (document.querySelector('.popup')) {
                return document.querySelector('.popup').outerHTML
            }
        }
    },
    methods: {
        initialize() {
            Radar.initialize(process.env.RADAR_KEY)
            // prevent duplicate maps from rendering over each other
            if (this.map) {
                this.map.remove()
            }
            this.map = Radar.ui.map({
                container: 'map',
            })
            // this.map.setStyle('radar-dark-v1')
            this.map.on('error', (err) => {
                console.error(err)
            })
        },
        addMarkers() {
            this.locations.forEach((location, i) => {
                const marker = document.createElement('div')
                marker.className = 'marker'
                marker.style.backgroundImage = 'url(' + this.marker.src + ')'
                marker.style.width = this.marker.width
                marker.style.height = this.marker.height
                marker.style.backgroundSize = '100%'
                marker.addEventListener('click', () => {
                    this.setActiveLocation(location)
                })
                if (!location.key && this.popups.show) { // all locations
                    const popup = new maplibregl.Popup({ offset: this.popups.offset })
                        .setLngLat([location.lng, location.lat])
                        .setHTML(this.popup)
                        .addTo(this.map)
                    new maplibregl.Marker(marker)
                        .setLngLat([location.lng, location.lat])
                        .setPopup(popup)
                        .addTo(this.map)
                } else { // search results
                    new maplibregl.Marker(marker)
                        .setLngLat([location.lng, location.lat])
                        .addTo(this.map)
                }
                // center the map to the first result on search
                if (i == 0 && this.locationList?.length) {
                    this.map.setZoom(11)
                    setTimeout(() => {
                        this.map.setCenter([location.lng, location.lat])
                    }, 250)
                }
            })
        },
        addMarker() {
            const marker = document.createElement('div')
            marker.className = 'marker'
            marker.style.backgroundImage = 'url(' + this.marker.src + ')'
            marker.style.width = this.marker.width
            marker.style.height = this.marker.height
            marker.style.backgroundSize = '100%'
            marker.addEventListener('click', () => {
                this.setActiveLocation(this.location)
            })
            new maplibregl.Marker(marker)
                .setLngLat([this.location.lng, this.location.lat])
                .addTo(this.map)
            this.map.setZoom(11)
            setTimeout(() => {
                this.map.setCenter([this.location.lng, this.location.lat])
            }, 250)
        },
        async setActiveLocation(location) {
            if (location.key) { // get full location information if user hasn't searched
                location = await this.$api.getLocation(location.key, false, false)
            }
            this.activeLocation = location
            this.map.fire('closePopup')
            setTimeout(() => {
                if (this.popups.show) {
                    const popup = new maplibregl.Popup({ offset: this.popups.offset, focusAfterOpen: false })
                        .setLngLat([location.lng, location.lat])
                        .setHTML(document.querySelector('.popup').outerHTML)
                        .addTo(this.map)
                    this.map.on('closePopup', () => {
                        popup.remove()
                    })
                }
                this.map.flyTo({
                    center: [location.lng, location.lat],
                    zoom: 11,
                    duration: 2000,
                    essential: true
                })
            }, 10)
        },
        openStatus(location) {
            let dayOfWeek = parseInt(moment().format('e'))
            let hours = location.hours[dayOfWeek]
            if (hours !== undefined) {
                let open = moment(hours.open, 'HH:mm')
                let close = moment(hours.close, 'HH:mm')
                let now = moment()
                let isOpen = now > open && now < close
                let tomorrow = dayOfWeek // today
                if (tomorrow == 7) { // if Sunday
                    tomorrow = 0
                } else {
                    tomorrow = dayOfWeek
                }
                let tomorrowHours = location.hours[tomorrow]
                let tomorrowOpen = location.hours[tomorrow].open
                let tomorrowClose = location.hours[tomorrow].close
                let tomorrowString = moment(tomorrow, 'e').format('ddd')
                if (moment(close).format('h:mm A') == '12:00 AM' && moment(open).format('h:mm A') == '12:00 AM' && hours.holiday == null) {
                    return 'Open 24 hours'
                } else if (moment(close).format('h:mm A') == '12:00 AM' && hours.holiday == null) {
                    return 'Open until midnight'
                } else if (isOpen & hours.holiday == null) {
                    return 'Open until ' + moment(close).format('h:mm A')
                } else if (moment(open).diff(moment(now)) > 0 && hours.holiday == null) { // not open yet today
                    return 'Opens at ' + moment(open).format('h:mm A')
                } else if (tomorrowHours.holiday == null) { // opens tomorrow and not holiday tomorrow
                    return 'Opens tomorrow at ' + moment(tomorrowOpen, 'HH:mm').format('h:mm A')
                } else { // closed and holiday tomorrow
                    return 'Closed for holiday'
                }
            } else {
                return 'Closed'
            }
        },
        phone(s) {
            let cleaned = ('' + s).replace(/\D/g, '')
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3]
            }
            return null
        },
        async startOrder(location) {
            this.$store.commit('setLocation', location)
            const menu = await this.$api.getMenu()
            await this.$api.abandonOrder()
            if (menu) {
                if (this.$route.query.order) {
                    this.$router.push(`/order/type/?order=${this.$route.query.order}`)
                } else {
                    this.$router.push('/order/')
                }
            }
        }
    },
    watch: {
        locations() {
            this.initialize()
            this.addMarkers()
        }
    }
}
