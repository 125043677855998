//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import Mapbox from 'mapbox-gl-vue'
import moment from 'moment'
import Radar from '~/node_modules/radar-sdk-js'
import AutocompleteUI from '~/node_modules/radar-sdk-js'
import stateList from '../../assets/src/usstates.json'

export default {
  components: { Mapbox },
  props: {
    blok: {
      type: Object,
      default: () => []
    }
  },
  data() {
    return {
      orderType: 'pickup',
      placeholder: null,
      address: '',
      building: '',
      city: '',
      zip: '',
      coordinates: null,
      addressLabel: '',
      phone: '',
      instructions: '',
      searched: false,
      accessToken: process.env.MAPBOX_TOKEN,
      mapStyle: 'mapbox://styles/tfk-dev/clb2fnrjg000a14mkqthegagv',
      center: [],
      favorited: false,
      showInfo: null,
      suggestions: [],
      searched: false,
      stateList: stateList,
      timeout: null,
      activeLocation: [],
      deliveryInfo: false,
      deliveryTime: 'asap',
      pickupInfo: false,
      pickupTime: 'asap',
      showSaved: false,
      column1: [0, 1, 2, 3], // monday - thursday
      column2: [4, 5, 6], // friday - sunday
      loading: false,
      newAddress: false,
      days: [],
      activeIcon: null,
      query: '',
      firstResult: [],
    }
  },
  head: {
    link: [
      { rel: 'stylesheet', href: '//api.tiles.mapbox.com/mapbox-gl-js/v1.5.1/mapbox-gl.css' },
    ]
  },
  async beforeMount() {
    if (this.$route.query.search) {
      this.address = this.$route.query.search
      this.locationSearch({ address: this.address })
    }
    this.$store.commit('setLocationList', null)
    if (this.order) {
      if (this.switchHandoff) {
        this.orderType = 'delivery'
      } else {
        this.orderType = this.order.handoff != 'pickup' ? 'delivery' : 'pickup'
      }
    }
    this.placeholder = this.orderType === 'delivery' ? 'Enter Delivery Address' : 'Search store, city, or zip'
    if (this.user) {
      await this.$api.getAddresses()
    }
    if (this.token) {
      await this.$api.getFaveLocations()
    }
    this.$store.commit('setSwitchHandoff', false)
  },
  mounted() {
    this.initializeAutoComplete()
  },
  computed: {
    ...mapState(['options', 'user', 'order', 'locationList', 'location', 'token', 'favelocations', 'switchHandoff']),
    dayOfWeek() {
      return parseInt(moment().format('e'))
    },
    geojson() {
      // load location coordinates to map
      let geojson = {
        'type': 'FeatureCollection',
        'features': []
      }
      let locations = []

      if (this.location) {
        locations.push(this.location)
      }

      if (this.locationList?.length) {
        locations = this.locationList
      }

      if (locations && locations.length) {
        for (var i = 0; i < locations.length; i++) {
          let l = locations[i]
          // let closed = l.closed ? 'Closed' : 'Open Now'
          let feature = {
            'type': 'Feature',
            'properties': {
              'location': l,
              'id': l.id,
              'available': l.available,
              'title': l.address + '<br> ' + l.city + ', ' + l.state + ' ' + l.zip,
              'distance': l.distance ? l.distance.toFixed(2) + ' mi | ' : '',
              'phone': l.phone,
              // 'distance': l.distance ? l.distance.toFixed(2)+ ' mi | ' : '' +closed,
              'url': this.options.locationPath + l.path,
              'iconSize': [36, 49]
              // 'iconSize': [60, 66.96]
            },
            'geometry': {
              'type': 'Point',
              'coordinates': [l.lng, l.lat]
            }
          }
          geojson.features.push(feature)
        }
      }
      this.activeIcon = geojson.features[0].properties.id
      return geojson
    },
    filteredFavoriteLocations() {
      return this.user.favelocations.slice(0, 3);
    },
    locations() {
      return this.locationList || this.allLocations
    },
    mq() {
      return this.$mq
    },
    states() {
      let states = new Object()
      states['Alabama'] = []
      states['Alaska'] = []
      states['Arizona'] = []
      states['Arkansas'] = []
      states['California'] = []
      states['Colorado'] = []
      states['Connecticut'] = []
      states['Delaware'] = []
      states['Florida'] = []
      states['Georgia'] = []
      states['Hawaii'] = []
      states['Idaho'] = []
      states['Illinois'] = []
      states['Indiana'] = []
      states['Iowa'] = []
      states['Kansas'] = []
      states['Kentucky'] = []
      states['Louisiana'] = []
      states['Maine'] = []
      states['Maryland'] = []
      states['Massachusetts'] = []
      states['Michigan'] = []
      states['Minnesota'] = []
      states['Mississippi'] = []
      states['Missouri'] = []
      states['Montana'] = []
      states['Nebraska'] = []
      states['Nevada'] = []
      states['New Hampshire'] = []
      states['New Jersey'] = []
      states['New Mexico'] = []
      states['New York'] = []
      states['North Carolina'] = []
      states['North Dakota'] = []
      states['Ohio'] = []
      states['Oklahoma'] = []
      states['Oregon'] = []
      states['Pennsylvania'] = []
      states['Rhode Island'] = []
      states['South Carolina'] = []
      states['South Dakota'] = []
      states['Tennessee'] = []
      states['Texas'] = []
      states['Utah'] = []
      states['Vermont'] = []
      states['Virginia'] = []
      states['Washington'] = []
      states['Washington DC'] = []
      states['West Virginia'] = []
      states['Wisconsin'] = []
      states['Wyoming'] = []
      for (let i = this.allLocations.length - 1; i >= 0; i--) {
        if (!this.allLocations[i].available) {
          continue
        }
        if (this.allLocations[i].state == 'AK') {
          states['Alaska'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'AL') {
          states['Alabama'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'AR') {
          states['Arkansas'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'AZ') {
          states['Arizona'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'CA') {
          states['California'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'CO') {
          states['Colorado'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'CT') {
          states['Connecticut'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'DE') {
          states['Delaware'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'FL') {
          states['Florida'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'GA') {
          states['Georgia'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'HI') {
          states['Hawaii'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'IA') {
          states['Iowa'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'ID') {
          states['Idaho'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'IL') {
          states['Illinois'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'IN') {
          states['Indiana'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'KS') {
          states['Kansas'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'KY') {
          states['Kentucky'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'LA') {
          states['Louisiana'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MA') {
          states['Massachusetts'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MD') {
          states['Maryland'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'ME') {
          states['Maine'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MI') {
          states['Michigan'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MN') {
          states['Minnesota'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MO') {
          states['Missouri'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MS') {
          states['Mississippi'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'MT') {
          states['Montana'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NC') {
          states['North Carolina'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'ND') {
          states['North Dakota'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NE') {
          states['Nebraska'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NH') {
          states['New Hampshire'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NJ') {
          states['New Jersey'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NM') {
          states['New Mexico'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NV') {
          states['Nevada'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'NY') {
          states['New York'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'OH') {
          states['Ohio'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'OK') {
          states['Oklahoma'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'OR') {
          states['Oregon'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'PA') {
          states['Pennsylvania'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'RI') {
          states['Rhode Island'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'SC') {
          states['South Carolina'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'SD') {
          states['South Dakota'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'TN') {
          states['Tennessee'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'TX') {
          states['Texas'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'UT') {
          states['Utah'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'VA') {
          states['Virginia'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'VT') {
          states['Vermont'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'WA') {
          states['Washington'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'DC') {
          states['Washington DC'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'WI') {
          states['Wisconsin'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'WV') {
          states['West Virginia'].push(this.allLocations[i])
        }
        if (this.allLocations[i].state == 'WY') {
          states['Wyoming'].push(this.allLocations[i])
        }
      }
      return states
    }
  },
  methods: {
    ...mapActions(['locationSearch', 'deliveryLocationSearch']),
    getHours(loc, i) {
      let day = this.getDay(i)
      for (let x = 0; x < loc.hours.length; x++) {
        if (day.substring(0, 3).toLowerCase() === loc.hours[x].day) {
          let string = `${moment(loc.hours[x].open, 'H:mm').format('h:mma')}-${moment(loc.hours[x].close, 'H:mm').format('h:mma')}`
          return string.replace(/\:00/g, '')
        }
      }
      return 'Closed'
    },
    radarSearchLocations(firstResult) {
      let result = firstResult
      if (!result?.formattedAddress) {
        result = this.firstResult
        let autocompleteList = document.querySelector('.radar-autocomplete-results-list');
        autocompleteList.setAttribute("hidden", "")
      }
      console.log("Result", result)

      this.coordinates = {
        lat: result.latitude,
        lng: result.longitude
      }

      if (this.orderType == 'pickup') {
        this.query = result.formattedAddress
        this.address = result.formattedAddress
        this.addressLabel = result.addressLabel
      }
      else {
        this.query = result.formattedAddress
        this.address = result.number && result.street ? result.number + ' ' + result.street : ''
        this.addressLabel = result.addressLabel
        this.city = result.city
        this.zip = result.postalCode
      }
    },
    getDay(i) {
      return moment(i, 'e').format('dddd')
    },
    getClose(loc) {
      let date = new Date()
      let day = date.getDay()
      if (loc && loc.hours) {
        // does not work for lab
        for (let x = 0; x < loc.hours.length; x++) {
          if (day === 0 && loc.hours[x].day === 'sun') {
            let string = `${moment(loc.hours[x].close, 'H:mm').format('h:mma')}`
            return string.replace(/\:00/g, '')
          } else if (day === 1 && loc.hours[x].day === 'mon') {
            let string = `${moment(loc.hours[x].close, 'H:mm').format('h:mma')}`
            return string.replace(/\:00/g, '')
          } else if (day === 2 && loc.hours[x].day === 'tue') {
            let string = `${moment(loc.hours[x].close, 'H:mm').format('h:mma')}`
            return string.replace(/\:00/g, '')
          } else if (day === 3 && loc.hours[x].day === 'wed') {
            let string = `${moment(loc.hours[x].close, 'H:mm').format('h:mma')}`
            return string.replace(/\:00/g, '')
          } else if (day === 4 && loc.hours[x].day === 'thu') {
            let string = `${moment(loc.hours[x].close, 'H:mm').format('h:mma')}`
            return string.replace(/\:00/g, '')
          } else if (day === 5 && loc.hours[x].day === 'fri') {
            let string = `${moment(loc.hours[x].close, 'H:mm').format('h:mma')}`
            return string.replace(/\:00/g, '')
          } else if (day === 6 && loc.hours[x].day === 'sat') {
            let string = `${moment(loc.hours[x].close, 'H:mm').format('h:mma')}`
            return string.replace(/\:00/g, '')
          }
        }
      }
      return '9pm'
    },
    setOrderType(type) {
      this.$store.commit('setLocationList', null)
      this.searched = false
      this.address = ''
      this.building = ''
      this.city = ''
      this.zip = ''
      this.instructions = ''
      if (this.user && this.user.addresses && this.user.addresses.length) {
        this.newAddress = false
      } else {
        this.newAddress = true
      }
      if (type == 'delivery') {
        this.orderType = 'delivery'
        this.placeholder = 'Enter a delivery address'
      } else {
        this.orderType = 'pickup'
        this.placeholder = 'Search store, city, or zip'
      }
      this.initializeAutoComplete()
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    initializeAutoComplete() {
      const radarKey = process.env.RADAR_KEY
      if (!radarKey) {
        this.$utils.log('LocationSearch - RADAR_KEY is missing')
        return
      }
      // Initialize Radar SDK
      Radar.initialize(radarKey)

      // Create autocomplete widgets
      Radar.ui.autocomplete({
        container: 'autocomplete',
        countryCode: 'US',
        responsive: true,
        placeholder: 'Search for a restaurant',
        onResults: (results) => {
          this.firstResult = results[0]
        },
        onSelection: (result) => {
          this.radarSearchLocations(result)
        },
      })

      // Add autofill functionality
      this.$nextTick(() => {
        document.addEventListener('keyup', (e) => {
          if (e.target.parentElement.parentElement.id === 'autocomplete') {
            this.query = e.target.value
          }
        })
      })
    },
    useExistingAddress(a) {
      this.address = a.address
      this.building = a.building
      this.city = a.city
      this.zip = a.zip
      this.instructions = a.instructions
      this.phone = a.phone
      this.checkDelivery()
      // this.searchLocations()
      // this.deliveryInfo = true
      // this.$nextTick(() => {
      //   const field = document.querySelector('[placeholder="Delivery Instructions"]')
      //   if (field) {
      //     field.focus()
      //   }
      // })
    },
    async searchLocations() {
      if (this.orderType === 'pickup') {
        // handle pick up location search
        console.log('searching pickup...')
        console.log(this)
        this.$store.commit('setLoading', true)
        this.$store.commit('setLocationList', null)

        let request = null

        if (this.coordinates) {
          request = await this.$api.findLocations(this.coordinates)
        } else {
          const showNoLocationErrors = false
          request = await this.$api.findLocations({ address: this.address }, showNoLocationErrors)

          // if no locations found, try searching by addressLabel, because some locations are found by address (formattedAddress) and some by addressLabel
          if (request && !request.locations.length) {
            request = await this.$api.findLocations({ address: this.addressLabel })
          }
        }

        if (request && !this.locationList[0]) {
          this.$store.commit('setErrors', ['No nearby locations found.'])
          this.$store.commit('setLoading', false)
          return
        }
        
        if (request) {
          this.searched = true
          this.center = [this.locationList[0].lng, this.locationList[0].lat]
          this.loading = true
          setTimeout(function () {
            this.loading = false
          }.bind(this), 10)
        }
        this.$store.commit('setLoading', false)
      } else {
        // handle delivery location search
        let errors = []
        if (!this.address) {
          errors.push('Address is required.')
        }
        if (!this.city) {
          errors.push('City is required.')
        }
        if (!this.zip) {
          errors.push('Zipcode is required.')
        }
        if (errors.length == 0) {
          this.checkDelivery()
        } else {
          this.$store.commit('setErrors', errors)
        }
      }
    },
    async checkDelivery() {
      this.$store.commit('setLoading', true)
      let search = null

      if (this.coordinates) {
        search = await this.$api.findLocations(this.coordinates)
      } else {
        const showNoLocationErrors = false
        search = await this.$api.findLocations({ address: `${this.address}, ${this.city}, ${this.zip}` }, showNoLocationErrors)
        
        // if no locations found, try searching by addressLabel, because some locations are found by address (formattedAddress) and some by addressLabel
        if (search && !search.locations.length) {
          search = await this.$api.findLocations({ address: addressLabel })
        }
      }

      if (search && search.locations.length > 0) {
        console.log(search.locations[0])
        this.$store.commit('setLocation', search.locations[0])
        this.deliveryInfo = true
      }

      this.$store.commit('setLoading', false)
    },
    async getAdvanceDays() {
      let from = moment().format('YYYYMMDD')
      let to = moment().add(this.location.services.advancedays, 'days').format('YYYYMMDD')
      let calendar = await this.$api.getCalendar(from, to)
      let method = 'default'
      if (this.order && (this.order.handoff == 'dispatch' || this.order.handoff == 'delivery')) {
        method = 'Delivery'
      } else if (this.order && this.order.handoff == 'curbside') {
        method = 'Curbside'
      }
      this.$store.commit('setDeliveryDays', null)
      calendar[method].map((d, i) => {
        let day = moment(d.open, 'YYYYMMDD H:mm')
        this.days.push({
          dayOfWeek: d.day,
          dayOfMonth: day.format('D'),
          fullDate: d.earliest || d.open,
          endTime: d.close
        })
      })
      this.$store.commit('setDeliveryDays', this.days)
    },
    async deliverySearch() {
      this.$store.commit('setActiveCategory', null)
      let errors = []
      if (errors.length == 0) {
        this.startDeliveryOrder()
      } else {
        this.$store.commit('setErrors', errors)
      }
    },
    async startDeliveryOrder() {
      let requestData = { address: this.address, city: this.city, zip: this.zip }
      if (this.deliveryTime != 'asap') {
        requestData.time = this.deliveryTime
      }

      let location = await this.$api.findDeliveryLocations(requestData)
      if (!location) {
        return
      } else {
        this.$store.commit('setLocation', location)
        await this.$api.getMenu()
      }
      if (!this.order || this.order.menu_id != this.location.menu_id) {
        // console.log('create new order')
        await this.$api.createOrder()
      }
      if (this.order) {
        let newDelivery = await this.$api.setAddress('dispatch', {
          address: this.address,
          building: this.building,
          city: this.city,
          zip: this.zip,
          phone: this.phone,
          instructions: this.instructions,
          save: true,
        })
        if (!newDelivery) {
          return
        }
        if (this.deliveryTime != 'asap') {
          let newTime = await this.$api.setTime(this.deliveryTime)
          if (!newTime) {
            return
          }
        }

        console.log('test order: ', this.order)

        console.log('1: ', this.$store.state.asap)
        // Set handoff time if ASAP is not selected
        // if (this.$store.state.asap === false) {
        //   console.log('2: ', this.$store.state.asap)
        //   this.setFutureTime()
        // }

        this.$router.push('/order/')
      }
    },
    async setFutureTime() {
      let day = this.days[this.$store.state.selectedDay]
      let earliest = moment(day.fullDate, 'YYYYMMDD H:mm').format('HH:mm')
      let latest = moment(day.endTime, 'YYYYMMDD H:mm').format('HH:mm')

      let formatSelected = moment(this.$store.state.selectedTime, 'h:mm A').format('HH:mm')

      let selected = day.fullDate.replace(/(\d{1,2})\:(\d{2})$/, formatSelected)
      let minTime = day.fullDate.replace(/(\d{1,2})\:(\d{2})$/, earliest)
      let maxTime = day.fullDate.replace(/(\d{1,2})\:(\d{2})$/, latest)

      if (maxTime.split(' ')[1] == '00:00') {
        maxTime = maxTime.split(' ')[0] + ' 11:59'
      }
      let isSameOrAfterMin = moment(selected, 'YYYYMMDD HH:mm').isSameOrAfter(moment(minTime, 'YYYYMMDD H:mm'), 'minutes')
      let isSameOrBeforeMax = moment(selected, 'YYYYMMDD HH:mm').isSameOrBefore(moment(maxTime, 'YYYYMMDD H:mm'), 'minutes')
      if ((isSameOrAfterMin && isSameOrBeforeMax) || minTime == maxTime) {
        let order = null
        if (this.order) {
          order = await this.$api.setTime(selected)
          console.log(order)
        }
      } else {
        this.$store.commit('setErrors', ['Select a time between ' + moment(minTime, 'H:mm').format('LT') + ' and ' + moment(maxTime, 'H:mm').format('LT') + '.'])
      }
    },
    isFave(menu_id) {
      return this.favelocations.find(f => f.menu_id.toString() === menu_id.toString()) ? true : false
    },
    async favorite(loc) {
      if (this.isFave(loc.menu_id)) {
        await this.$api.deleteFaveLocation(loc.menu_id)
      } else {
        await this.$api.setFaveLocation(loc.menu_id)
      }
    },
    setInfo(loc) {
      if (this.showInfo === loc.id) {
        this.showInfo = null
      } else {
        this.showInfo = loc.id
      }
    },
    async setPickUpTime(loc) {
      this.$store.commit('setActiveCategory', null)
      if (!loc.services.pickup) {
        this.$store.commit('setErrors', ['Online ordering for this location is currently unavailable. Please call the restaurant to place a pick-up order.'])
      } else {
        if (loc.payments) {
          this.$store.commit('setLocation', loc)
        } else {
          let location = await this.$api.getLocation(loc.menu_id, true)
          this.$store.commit('setLocation', location)
          this.searched = true
          this.center = [this.location.lng, this.location.lat]
          this.loading = true
          setTimeout(function () {
            this.loading = false
          }.bind(this), 10)
        }

        if (this.location) {
          this.getAdvanceDays()
          this.pickupInfo = true
        }
      }
    },
    async startPickupOrder() {
      if (!this.order || this.order.menu_id != this.location.menu_id) {
        await this.$api.createOrder()
      }
      if (this.order) {
        let response = true
        if (this.pickupTime != 'asap') {
          response = await this.$api.setTime(this.pickupTime)
        }
        if (response && !this.location.services.pickup) {
          this.$store.commit('setErrors', ['Pickup is not supported for this location.'])
        } else if (response) {
          await this.$api.setHandoff('pickup')
          this.$router.push('/order')
        }
      }
    },
    // loaded(map) {
    //   // Add markers to the map.
    //   for (const marker of this.geojson.features) {
    //     // Create a DOM element for each marker.
    //     const el = document.createElement('div')
    //     const width = marker.properties.iconSize[0]
    //     const height = marker.properties.iconSize[1]
    //     if (this.activeIcon === marker.properties.id) {
    //       el.className = 'marker'
    //       el.style.backgroundImage = 'url(https://a.storyblok.com/f/177063/48x65/e69179b243/23_tfk_locationtag.png)'
    //       el.style.width = `${width}px`
    //       el.style.height = `${height}px`
    //       el.style.backgroundSize = '100%'
    //     } else {
    //       el.className = 'marker'
    //       el.style.width = '25px'
    //       el.style.height = '25px'
    //       el.style.border = '5px solid #fff'
    //       el.style.borderRadius = '100%'
    //       el.style.backgroundColor = '#3B3D2F'
    //     }

    //     el.addEventListener('click', () => {
    //       // this.activeIcon = marker.properties.id
    //       this.activeLocation = marker.properties.location
    //       let list = document.querySelectorAll('[data-loc]')
    //       if (list) {
    //         for (var i = list.length - 1; i >= 0; i--) {
    //           list[i].classList.remove('active')
    //         }
    //       }
    //       let active = document.querySelector('[data-loc="'+marker.properties.id+'"]')
    //       if (active) {
    //         active.classList.add('active')
    //       }

    //       // create the popup
    //       const popup = new mapboxgl.Popup({ offset: 28 }).setDOMContent(this.$refs.popup)
    //       // const popup = new mapboxgl.Popup({ offset: 37 }).setDOMContent(this.$refs.popup)
    //       // create the marker
    //       new mapboxgl.Marker(el)
    //       .setLngLat({ lng: marker.geometry.coordinates[0], lat: marker.geometry.coordinates[1] })
    //       .setPopup(popup) // sets a popup on this marker
    //       .addTo(map)
    //       if (!this.geolocation || this.geolocation.length !== 2) {
    //         map.flyTo({
    //           center: [marker.geometry.coordinates[0],marker.geometry.coordinates[1]],
    //           essential: true // this animation is considered essential with respect to prefers-reduced-motion
    //         , zoom: 13})
    //       }
    //       popup.on('close', function(e) {
    //         // Make sure the popup gets closed
    //         const remainingPopupElementsOpen = document.querySelectorAll('.mapboxgl-popup.mapboxgl-popup-anchor-bottom')
    //         remainingPopupElementsOpen.forEach((element) => element.remove())
    //       })
    //     })
    //     // Add markers to the map.
    //     new mapboxgl.Marker(el)
    //     .setLngLat(marker.geometry.coordinates)
    //     .addTo(map)
    //   }
    //   if (this.geolocation && this.geolocation.lng && this.geolocation.lat) {
    //     // Create a DOM element for geolocation
    //     const el = document.createElement('div')
    //     const width = 50
    //     const height = 50
    //     el.className = 'marker'
    //     el.style.backgroundImage = 'url(https://a.storyblok.com/f/177063/48x65/e69179b243/23_tfk_locationtag.png)'
    //     el.style.width = `${width}px`
    //     el.style.height = `${height}px`
    //     el.style.backgroundSize = '100%'
    //     new mapboxgl.Marker(el)
    //     .setLngLat({ lng: this.geolocation.lng, lat: this.geolocation.lat })
    //     .addTo(map)
    //     map.flyTo({
    //       center: [this.geolocation.lng,this.geolocation.lat],
    //       essential: true
    //     , zoom: 12})
    //   }
    // },
    open(loc) {
      if (loc.hours) {
        let hours = loc.hours[new Date().getDay()]
        if (hours !== undefined) {
          let time = moment(hours.date + ' ' + hours.close, 'YYYY-MM-DD H:mm').format('h a')
          if (loc.closed) {
            return 'Opens at ' + time
          } else {
            return 'Open til ' + time
          }
        }
      }
    },
    geoLocate() {
      if (navigator.geolocation) {
        this.$store.commit('setLoading', true)
        navigator.geolocation.getCurrentPosition(this.getCoordinates, this.getCoordinatesError)
      } else {
        this.$store.commit('setErrors', ['Please enable location services and try again.'])
      }
    },
    async getCoordinates(position) {
      let coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      this.showMap = false
      await this.$api.findLocations(coords)
      this.showMap = true
      this.$store.commit('setLoading', false)
    },
    getCoordinatesError() {
      this.$store.commit('setLoading', false)
      this.$store.commit('setErrors', ['We couldn’t locate you. Please enter your address.'])
    },
    autocomplete(e) {
      if (this.orderType == 'delivery') {
        window.clearTimeout(this.timeout)
        if (e.key != 'Shift' && this.address && this.address.length > 4) {
          this.timeout = setTimeout(function () {
            this.$axios({
              method: 'get',
              url: `${window.location.origin}/api/autocomplete`,
              params: {
                query: this.address
              }
            }).then(res => {
              this.suggestions = res.data.features
            }).catch(err => {
              this.suggestions = []
            })
          }.bind(this), 1000)
        }
      }
    },
    autofill(suggestion) {
      const parts = suggestion.place_name.replace(', United States', '').slice(0, -6).split(', ')
      this.address = parts[0]
      this.city = parts[1]
      for (let i = 0; i < suggestion.context.length; i++) {
        if (suggestion.context[i].text && !isNaN(suggestion.context[i].text) && suggestion.context[i].text.length == 5) {
          // is zipcode
          this.zip = suggestion.context[i].text
          break
        }
      }
      this.suggestions = []
      this.$nextTick(() => {
        const field = document.querySelector('[placeholder="Suite, Apartment, Building, Floor, Etc."]')
        if (field) {
          field.focus()
        }
      })
      this.checkDelivery()
      // this.searchLocations()
      // this.deliveryInfo = true
    },
    getDistance(loc) {
      // console.log(loc, this.center[0], this.center[1])
      var lat1 = this.center[1]
      var lon1 = this.center[0]
      var lat2 = loc.lat
      var lon2 = loc.lng

      var R = 6371 // km 
      //has a problem with the .toRad() method below.
      var x1 = lat2 - lat1
      var dLat = this.toRad(x1)
      var x2 = lon2 - lon1
      var dLon = this.toRad(x2)
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      var d = R * c
      let distance = d + this.locationList[0].distance


      return distance.toFixed(2)
    },
    toRad(x) {
      return x * Math.PI / 180
    },
    handleTimeUpdate(time) {
      console.log('handleTimeUpdate', time)
      if (this.orderType === 'delivery') {
        this.deliveryTime = time
        // this.searchLocations()
      } else if (this.orderType === 'pickup') {
        this.pickupTime = time
      }
    },
    setHandoffType(type) {
      if (this.location.id === "1050") {
        this.$store.commit('setLocation', {})
      }

      if (type === 'pickup') {
        this.pickupInfo = false
      } else if (type === 'delivery') {
        this.deliveryInfo = false
      }
    }
  },
  watch: {
    switchHandoff() {
      this.orderType = 'delivery'
      this.$store.commit('setSwitchHandoff', false)
    }
  }
}
