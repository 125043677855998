//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment-timezone'
import { mapState } from 'vuex'

export default {
  props: {
    onTimeUpdate: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState([ 'location', 'order' ]),
  },
  data() {
    return {
      days: [],
      times: [],
      selectedDay: null,
      selectedTime: null,
      minTime: null,
      maxTime: null,
      handoffTime: true,
      deliveryDays: [],
      disableAsap: false,
      locClosed: false,
      locClosedFade: false,
      locClosedHeight: 'auto',
    }
  },
  async mounted() {
    let i = 0
    while(i < 14) {
      let day = moment().add(i, 'days')
      this.deliveryDays.push({
        dayOfWeek: day.format('ddd'),
        dayOfMonth: day.format('D'),
        fullDate: day.format('YYYYMMDD')
      })
      i++
    }
    if(this.location.currently_open) {
      console.log('store is open')
      this.locClosed = false
      this.$store.commit('setAsap', true)
      this.$store.commit('setSelectedDay', null)
      this.$store.commit('setSelectedTime', null)
    } else {
      console.log('store not open')
      this.handoffTime = false
      this.disableAsap = true

      if (this.order?.time?.mode === 'asap' && this.order?.time?.earliest) {
        for (let i = 0; i < this.deliveryDays.length; i++) {
          if (this.order.time.earliest.includes(this.deliveryDays[i].fullDate)) {
            this.selectedDay = i
            this.getTime(this.selectedDay)
            for (let x = 0; x < this.times.length; x++) {
              let orderTime = moment(this.order.time.earliest, 'YYYYMMDD HH:mm').format('h:mm A')
              if (orderTime === this.times[x]) {
                this.selectedTime = this.times[x]
              }
            }
          }
        }
      } else {
        if (moment().hour() >= 12) {
          this.deliveryDays.shift()
        }
        let defaultDay = 0
        this.selectedDay = defaultDay
        this.getTime(defaultDay)
        this.selectedTime = this.times[0]
      }
      
      this.setTime()
      this.$store.commit('setAsap', false)
      this.$store.commit('setSelectedDay', this.selectedDay)
      this.$store.commit('setSelectedTime', this.selectedTime)

      // shows up the ASAP Unavailable message
      this.locClosed = true
      setTimeout(() => this.locClosedHeight = `${this.$refs?.locClosed?.offsetHeight ?? 0}px`, 0)
      setTimeout(() => {
        this.locClosedFade = true
        this.locClosedHeight = '0px'
      }, 7000)
    }
  },
  methods: {
    setAsap(value) {
      this.onTimeUpdate('asap')
      if (value === true) {
        this.$store.commit('setSelectedDay', null)
        this.$store.commit('setSelectedTime', null)
      }
      this.$store.commit('setAsap', value)
    },
    setOrderAhead(value) {
      this.$store.commit('setAsap', false)
    },
    getDay(day) {
      const newDay = moment(`${day.fullDate}`, 'YYYYMMDD').format('MMM D, YYYY')
      return newDay
    },
    getTime(d) {
      if (d !== null) {
        this.$store.commit('setLoading', true)
        this.selectedTime = null
        this.times = []
        let day = this.deliveryDays[d]
        let minTime = null
        let maxTime = null
        
        const { hours = [] } = this.location
        hours.map(h => {
          if (day.dayOfWeek.toLowerCase() === h.day) {
            minTime = parseInt(h.open.slice(0, 2))
            maxTime = parseInt(h.close.slice(0, 2))
          }
        })
        let now = moment().utc().add(this.location.utcOffset, 'hours').add(30, 'm')
        let today = moment(day.fullDate).utc().startOf('day')
        let open = moment(day.fullDate).utc().startOf('day').set('hour', minTime)
        let close = moment(day.fullDate).utc().startOf('day').set('hour', maxTime)
        while(today < moment(day.fullDate).utc().add(1, 'day').startOf('day')) {
          if (today.isSameOrAfter(now) && today.isSameOrAfter(open) && today.isSameOrBefore(close)) {
            this.times.push(today.format('h:mm A'))
          }
          today.add(15, 'm')
        }
        this.$store.commit('setLoading', false)
      } else {
        this.selectedTime = null
        this.times = []
      }
    },
    setTime() {
      let day = this.deliveryDays[this.selectedDay]
      if(day && this.selectedTime) {
        this.onTimeUpdate(moment(`${day.fullDate} ${this.selectedTime}`, 'YYYYMMDD h:mm a').format('YYYYMMDD HH:mm'))
      }
      // // console.log(this.deliveryDays[this.selectedDay], this.selectedTime)
      // this.$store.commit('setSelectedTime', time)
    }
  }
}
